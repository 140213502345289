import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletConfig } from "../components/Web3Automation";
import { RootState } from "./store";

export interface WarmState {
  walletConfig: WalletConfig;
}

const initialState: WarmState = {
  walletConfig: {
    coldWallet: [],
    hotWallet: "",
  },
};

export const warmSlice = createSlice({
  name: "wamXYZ",
  initialState,
  reducers: {
    setWarmWalletConfig: (state, action: PayloadAction<WalletConfig>) => {
      state.walletConfig = action.payload;
    },
  },
});

export const { setWarmWalletConfig } = warmSlice.actions;

export const selectWarmWalletConfig = (state: RootState): WalletConfig =>
  state.warm.walletConfig;

export default warmSlice.reducer;
