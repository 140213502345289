import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "../state/uwuSlice";
import Button from "./Button";

const NavigateButton = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin =
    process.env.REACT_APP_TEST_FLAG === "true" &&
    location.pathname.includes("admin");
  const dispatch = useDispatch();

  const navigateToHome = () => {
    dispatch(resetState());
    navigate("/");
  };

  const navigateToAdmin = () => {
    dispatch(resetState());
    navigate("/admin");
  };

  return process.env.REACT_APP_TEST_FLAG === "true" ? (
    <Button primary click={isAdmin ? navigateToHome : navigateToAdmin}>
      {isAdmin ? "Home" : "Admin"}
    </Button>
  ) : (
    <div />
  );
};

export default NavigateButton;
