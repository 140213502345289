import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { ChainId, Config, DAppProvider } from "@usedapp/core";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Error from "./components/Error";
import { ALCHEMY_ID, GOERLI_ID } from "./app/globals";
import WalletSelector from "./components/WalletSelector";
import Web3Automation from "./components/Web3Automation";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const config: Config = {
  autoConnect: false,
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
    [ChainId.Goerli]: `https://eth-goerli.g.alchemy.com/v2/${GOERLI_ID}`,
  },
};

const App = (): JSX.Element => {
  return (
    <StyledApp>
      <DAppProvider config={config}>
        <Header />
        <Outlet />
        <Footer />
        <Error />
        <WalletSelector />
        <Web3Automation />
      </DAppProvider>
    </StyledApp>
  );
};

export default App;
