export const UWU_QUEST_STAMPS = "0xcec3597d2fddd38339d26d5097691ad29330456d";
export const CC0_THE_CREW = "0x534E430ab0B423a346097236b589Dd6862d13Fc6";
const UWU_INSIGNIA = "0xb2630e52faaf487d277cabe213cd56bdca17ca11";
const PERSONA_LAMPS = "0xa3b041ee6b56bccbc54a3048417d82fe67736f62";
const KILLER_GF = "0x6bE69b2A9B153737887CfcdCa7781eD1511C7e36";

export interface TraitRestriction {
  trait: string;
  value: string;
}

export interface TokenRestriction1155 {
  token: string;
  ids: string[];
  chain: string;
}

export interface OptionType {
  name: string;
  testOnly?: boolean;
  fullbody?: boolean;
  traitRestriction?: TraitRestriction;
  tokenRestriction?: string;
  tokenRestriction1155?: TokenRestriction1155;
}

export interface OptionCategoryType {
  trait:
    | "background"
    | "outfit"
    | "skin"
    | "eyes"
    | "hair"
    | "hat"
    | "mouth"
    | "left accessory"
    | "right accessory"
    | "eyewear";
  options: OptionType[];
}

export interface AdminOptionCategoryType {
  trait: string;
  options: OptionType[];
}

export const adminOptions: OptionCategoryType[] = [];

const options: OptionCategoryType[] = [
  {
    trait: "outfit",
    options: [
      {
        name: "Snow Cloak",
        fullbody: true,
      },
      {
        name: "Blue Bunny Dress",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["5"],
          chain: "matic",
        },
      },
      {
        name: "Celestial Dress",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["3"],
          chain: "matic",
        },
      },
      {
        name: "uwu Hoodie",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["2"],
          chain: "matic",
        },
      },
      {
        name: "uwu Shirt",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["2"],
          chain: "matic",
        },
      },
      {
        name: "Robes of Helios",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["1"],
          chain: "matic",
        },
      },
      {
        name: "Birthday Dress",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["0"],
          chain: "matic",
        },
      },
      {
        name: "Cerise Outfit",
        tokenRestriction: KILLER_GF,
      },
      {
        name: "KGF Pink Racer",
        tokenRestriction: KILLER_GF,
        traitRestriction: {
          trait: "outfit",
          value: "Racer",
        },
      },
      {
        name: "Red Qipao",
        traitRestriction: {
          trait: "outfit",
          value: "Qipao",
        },
      },
      {
        name: "Reaper Robe",
        traitRestriction: {
          trait: "right accessory",
          value: "Gold Scythe",
        },
      },
      {
        name: "Ugly uwu Sweater",
      },
      {
        name: "Demon Queen",
      },
      {
        name: "Nymph Dress",
      },
      {
        name: "uwuminati Tee",
      },
      {
        name: "Red Bunny",
        traitRestriction: {
          trait: "outfit",
          value: "Bunny",
        },
      },
      {
        name: "White Bunny",
        traitRestriction: {
          trait: "outfit",
          value: "Bunny",
        },
      },
      {
        name: "Blue Bunny",
        traitRestriction: {
          trait: "outfit",
          value: "Bunny",
        },
      },
      {
        name: "Pink Sweater",
        traitRestriction: {
          trait: "outfit",
          value: "Orange Sweater",
        },
      },
      {
        name: "Maroon Sweater",
        traitRestriction: {
          trait: "outfit",
          value: "Orange Sweater",
        },
      },
      {
        name: "Olive Sweater",
        traitRestriction: {
          trait: "outfit",
          value: "Orange Sweater",
        },
      },
      {
        name: "Navy Sweater",
        traitRestriction: {
          trait: "outfit",
          value: "Orange Sweater",
        },
      },
      {
        name: "Xmas Dress",
      },
      {
        name: "Xmas Buttonup",
      },
      {
        name: "Red uwudas",
      },
      {
        name: "Grey uwudas",
      },
      {
        name: "Black uwudas",
      },
      {
        name: "Pink uwudas",
      },
    ],
  },
  {
    trait: "hat",
    options: [
      {
        name: "Snow Earmuffs",
      },
      {
        name: "Pumpkin Head",
      },
      {
        name: "Spooky Ribbon",
      },
      {
        name: "Blue Bunny Bow",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["5"],
          chain: "matic",
        },
      },
      {
        name: "Clown Hat",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["4"],
          chain: "matic",
        },
      },
      {
        name: "Celestial Hairpiece",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["3"],
          chain: "matic",
        },
      },
      {
        name: "Eye of Ra",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["1"],
          chain: "matic",
        },
      },
      {
        name: "uwu Hat",
      },
      {
        name: "Crimson Mouse",
        tokenRestriction: KILLER_GF,
      },
      {
        name: "LNY Ornament",
      },
      {
        name: "Miss Kitty",
        traitRestriction: {
          trait: "hat",
          value: "Mr. Whiskers",
        },
      },
      {
        name: "Demon Crown",
      },
      {
        name: "Nymph Hairpin",
      },
      {
        name: "Bunny Hair Clip",
      },
      {
        name: "Party Hat",
      },
      {
        name: "uwuminati Cap",
      },
      {
        name: "Red Bunny Ears",
        traitRestriction: {
          trait: "hat",
          value: "Bunny Ears",
        },
      },
      {
        name: "White Bunny Ears",
        traitRestriction: {
          trait: "hat",
          value: "Bunny Ears",
        },
      },
      {
        name: "Candy Cane Bow",
      },
    ],
  },
  {
    trait: "left accessory",
    options: [
      {
        name: "Demon Pop",
        traitRestriction: {
          trait: "left accessory",
          value: "Lollipop",
        },
      },
      {
        name: "Demon Hand",
      },
      {
        name: "Monke",
      },
      {
        name: "Party Balloon",
      },
    ],
  },
  {
    trait: "right accessory",
    options: [
      {
        name: "Celestial Staff",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["3"],
          chain: "matic",
        },
      },
      {
        name: "Devil Scythe",
        tokenRestriction: KILLER_GF,
        traitRestriction: {
          trait: "right accessory",
          value: "Gold Scythe",
        },
      },
      {
        name: "Hot Cocoa",
      },
      {
        name: "Hyperion",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["1"],
          chain: "matic",
        },
      },
      {
        name: "Bone Scythe",
        traitRestriction: {
          trait: "right accessory",
          value: "Gold Scythe",
        },
      },
      {
        name: "Demon Hand",
      },
      {
        name: "Slice of Cake",
        tokenRestriction1155: {
          token: UWU_QUEST_STAMPS,
          ids: ["10"],
          chain: "eth",
        },
      },
    ],
  },
  {
    trait: "eyewear",
    options: [
      {
        name: "Clown Nose",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["4"],
          chain: "matic",
        },
      },
      {
        name: "Cerise Glasses",
        tokenRestriction: KILLER_GF,
      },
    ],
  },
  {
    trait: "background",
    options: [
      { name: "Transparent" },
      { name: "Snow Day" },
      { name: "Lunar New Year" },
      { name: "Spooky Sky" },
      {
        name: "Party Sky",
      },
      {
        name: "Persona Lamps Winter",
        tokenRestriction: PERSONA_LAMPS,
      },
      {
        name: "Celestial Sky",
        tokenRestriction1155: {
          token: UWU_INSIGNIA,
          ids: ["3"],
          chain: "matic",
        },
      },
    ],
  },
];

export default options;
