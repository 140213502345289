import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Connector from "./Connector";

import logo from "../assets/logos/logo.svg";
import NavigateButton from "./NavigateButton";

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;

  @media (max-width: 600px) {
    padding: 1rem;
  }
  z-index: 100;
`;

const LogoLink = styled.a`
  z-index: 100;
  position: relative;
  transform: translate(0, 0);
`;

const Logo = styled.img`
  width: 9rem;
  cursor: pointer;
`;

const Header = (): JSX.Element => {
  const location = useLocation();
  const isAdmin =
    process.env.REACT_APP_TEST_FLAG === "true" &&
    location.pathname.includes("admin");

  return (
    <StyledHeader>
      <LogoLink href="https://uwucrew.art/">
        <Logo src={logo} alt="uwucrew logo" />
      </LogoLink>
      <NavigateButton />
      {!isAdmin && <Connector />}
    </StyledHeader>
  );
};

export default Header;
