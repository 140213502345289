import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Metadata, setMetadata } from "../../state/uwuSlice";
import { ActiveBalances } from "../../components/Web3Automation";
import { fetchTokenIdMulticall } from "../../utils/multicall";
import { OwnerIds } from "../../utils/delegation";
import { HardcodedUwuPreview, UwuPreview } from "./UwuPreview";

const GridContainer = styled.div`
  flex: 1;
  width: 100%;
  background: var(--bg);
  overflow: auto;
  padding-top: 2rem;
  padding-right: 2rem;
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 0.4fr));
  grid-gap: 2rem;
`;

interface Props {
  active: ActiveBalances[];
  close: () => void;
  cc0: number[];
}

export const UwuBalance = ({ active, close, cc0 }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [uwus, setUwus] = useState<OwnerIds[]>([]);

  console.log("UwuBalance uwus", uwus);

  useEffect(() => {
    // Fetch all uwu ids
    const fetchUwuIds = async () => {
      let ownerIds: OwnerIds[] = uwus;
      const addresses = uwus.map((o) => o.address);
      const results = await fetchTokenIdMulticall(active);
      ownerIds = ownerIds.concat(results);

      const filtered = ownerIds.filter(
        ({ address }, index) => !addresses.includes(address, index + 1)
      );
      setUwus(filtered);
    };
    if (active.length === 0) return;
    fetchUwuIds().catch(console.error);
  }, [active.length]);

  return (
    <GridContainer>
      <Grid>
        {uwus.map(({ ids, address }) => {
          return ids.map((j: number) => (
            <UwuPreview
              key={j}
              uwuId={j}
              select={(metadata: Metadata) => {
                dispatch(setMetadata(metadata));
                close();
              }}
              coloInfo={active.find(
                (o: ActiveBalances) => o.address === address
              )}
            />
          ));
        })}

        {cc0.map((i: number) => (
          <HardcodedUwuPreview
            key={i}
            uwuId={i}
            select={(metadata: Metadata) => {
              dispatch(setMetadata(metadata));
              close();
            }}
          />
        ))}
      </Grid>
    </GridContainer>
  );
};
