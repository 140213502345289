import { ReactNode } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: solid 2px var(--border);
  background: var(--bg);
`;

interface Props {
  children: ReactNode;
}

const Container = ({ children }: Props): JSX.Element => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default Container;
