import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Contract } from "ethers";
import { Delegate, setDelegates } from "../state/delegateCash";
import { DELEGATE_CASH, WARM_XYZ } from "../app/globals";
import warm_abi from "../contracts/abis/warm_xyz.json";
import delegate_abi from "../contracts/abis/delegate_cash.json";

import { setWarmWalletConfig } from "../state/warmXYZ";

// Delegation services base interface
export interface WalletConfig {
  coldWallet: string[];
  hotWallet: string;
}
// WalletBalances interface is an object with keys of all wallets (connected , delegate.cash, and warm.xyz) that we get back and their respective balance amounts.
export interface WalletBalances {
  address: string | null | undefined;
  balance: number[] | number;
  isDelegate?: boolean;
}
// ActiveBalances interface is all the wallet who have a balance greater than 0 and their key indexes based of balance amount
export interface ActiveBalances {
  address: string | null | undefined;
  balance: number[] | number;
  keys: number[];
  isDelegate?: boolean;
}

export const flattenDelegates = (delegates: Delegate[]): WalletConfig => {
  const wc: WalletConfig = {
    hotWallet: "",
    coldWallet: [],
  };

  delegates.forEach((d: Delegate) => {
    // no hot wallet defined, initializing new walletConfig
    if (!wc.hotWallet) {
      wc.hotWallet = d.delegate;
      wc.coldWallet.push(d.vault);
    }
    // existing hot wallet defined, adding new cold wallet
    else if (
      wc.coldWallet.includes(d.delegate) &&
      wc.hotWallet === d.delegate
    ) {
      wc.coldWallet.push(d.vault);
    }
  });
  return wc;
};

const Web3Automation = (): JSX.Element => {
  const dispatch = useDispatch();
  const { account, library } = useEthers();

  useEffect(() => {
    // delegate.cash
    const combinedPromises = async () => {
      const signer = library?.getSigner(String(account));

      const fetchDelegateCash = async () => {
        const contract = new Contract(DELEGATE_CASH, delegate_abi, signer);
        const delegates = await contract.getDelegationsByDelegate(account);
        const delegateCashWalletConfig = flattenDelegates(delegates);
        dispatch(setDelegates(delegateCashWalletConfig));
        return delegateCashWalletConfig;
      };

      // warm.xyz
      const fetchHotWallets = async () => {
        let res: WalletConfig = {
          coldWallet: [],
          hotWallet: "",
        };
        if (typeof account === "string") {
          const contract = new Contract(WARM_XYZ, warm_abi, signer);
          const coldWallets = await contract.getColdWallets(account);
          if (coldWallets.length > 0) {
            res = {
              coldWallet: coldWallets,
              hotWallet: account,
            };
          }
          dispatch(setWarmWalletConfig(res));
        }
        return res;
      };

      await Promise.all([fetchDelegateCash(), fetchHotWallets()]);
    };
    if (!library) return;
    combinedPromises();
  }, [account]);

  /**
   * Managing state and rendering of uwu data got tangled since react would preserve the
   * values and no reinitialize. Added so on wallet change we can just set the data according
   * to wallet connected and services that wallet is connected to
   */
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  });
  return <div />;
};
export default Web3Automation;
