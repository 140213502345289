import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletConfig } from "../components/Web3Automation";
import { RootState } from "./store";

export interface Delegate {
  type: "NONE" | "ALL" | "CONTRACT" | "TOKEN";
  vault: string;
  delegate: string;
  contract: string;
  tokenId: number;
}

export interface DelegateState {
  walletConfig: WalletConfig;
}

const initialState: DelegateState = {
  walletConfig: {
    coldWallet: [],
    hotWallet: "",
  },
};

export const delegateSlice = createSlice({
  name: "delegateCash",
  initialState,
  reducers: {
    setDelegates: (state, action: PayloadAction<WalletConfig>) => {
      state.walletConfig = action.payload;
    },
  },
});

export const { setDelegates } = delegateSlice.actions;

export const selectDelegates = (state: RootState): WalletConfig =>
  state.delegate.walletConfig;

export default delegateSlice.reducer;
