import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import delegateReducer from "./delegateCash";
import errorReducer from "./errorSlice";
import uiReducer from "./uiSlice";
import uwuReducer from "./uwuSlice";
import warmReducer from "./warmXYZ";

export const store = configureStore({
  reducer: {
    delegate: delegateReducer,
    error: errorReducer,
    ui: uiReducer,
    uwu: uwuReducer,
    warm: warmReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
