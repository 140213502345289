import styled from "styled-components";
import { ActiveBalances } from "../../components/Web3Automation";
import { useImage, useMetadata } from "../../contracts/views";
import { Metadata } from "../../state/uwuSlice";

interface ColorProps {
  isDelegate: boolean | undefined;
}

const Button = styled.button`
  cursor: pointer;
  aspect-ratio: 1.04998973363;
  padding: 0.5rem;
  background-color: var(--bg);
  border: ${(props: ColorProps) =>
    props.isDelegate ? "0.5rem solid var(--glacier)" : "none"};
`;

const StyledUwuPreview = styled.img`
  width: 100%;

  transform: scale(1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.3s;
  :hover {
    transform: scale(1.03);
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.6);
  }
`;

interface Props {
  uwuId: number;
  select: (metadata: Metadata) => void;
  coloInfo: ActiveBalances | undefined;
}

interface HardcodedProps {
  uwuId: number;
  select: (metadata: Metadata) => void;
}

export const UwuPreview = ({ uwuId, select, coloInfo }: Props): JSX.Element => {
  const metadata = useMetadata(uwuId);
  const image = useImage(uwuId);
  const isDelegate = coloInfo !== undefined ? coloInfo.isDelegate : false;
  return (
    <Button
      onClick={() => {
        if (!metadata) return;
        select(metadata);
      }}
      isDelegate={isDelegate}
    >
      <StyledUwuPreview src={image} />
    </Button>
  );
};

export const HardcodedUwuPreview = ({
  uwuId,
  select,
}: HardcodedProps): JSX.Element => {
  const metadata = useMetadata(uwuId);
  const image = useImage(uwuId);

  return (
    <Button
      onClick={() => {
        if (!metadata) return;
        select(metadata);
      }}
      isDelegate={false}
    >
      <StyledUwuPreview src={image} />
    </Button>
  );
};
