import { useSelector } from "react-redux";
import styled from "styled-components";
import Container from "../../components/Container";
import options, {
  OptionCategoryType,
  CC0_THE_CREW,
} from "../../config/options";

import { use1155TokensOwned, useTokensOwned } from "../../contracts/views";
import {
  selectTraits,
  selectOriginalTraits,
  selectId,
  selectFullbody,
} from "../../state/uwuSlice";
import OptionCategory from "./OptionCategory";

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40vw;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Header = styled.div`
  font-size: 4.5rem;
  font-weight: 700;
  width: 100%;
  margin-bottom: 3rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(15rem, 1fr));
  grid-gap: 2rem;
  width: 100%;
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
`;

interface Props {
  address: string | null | undefined;
}

const reduceText = (text: string): string => {
  return text.toLowerCase().replaceAll(" ", "").replaceAll("-", "");
};

const Options = ({ address }: Props): JSX.Element | null => {
  const ogTraits = useSelector(selectOriginalTraits);
  const uwuId = useSelector(selectId);
  const fullbody = useSelector(selectFullbody);
  const tokensOwned = useTokensOwned();
  const tokensOwned1155 = use1155TokensOwned();
  const traits = useSelector(selectTraits);
  if (!traits) return null;

  let allowAll = false;
  if (address === CC0_THE_CREW) {
    allowAll = true;
  }

  return (
    <Container>
      <StyledOptions>
        <Header>Dressing Room</Header>
        <Grid>
          {options.map((option: OptionCategoryType) => (
            <OptionCategory
              allowAll={allowAll}
              key={option.trait}
              name={option.trait}
              ogTraits={ogTraits}
              options={option.options}
              tokensOwned={tokensOwned}
              tokensOwned1155={tokensOwned1155}
              traits={traits}
              fullbody={fullbody}
              uwuId={uwuId}
            />
          ))}
        </Grid>
      </StyledOptions>
    </Container>
  );
};

export default Options;
