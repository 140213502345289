import {
  ActiveBalances,
  WalletBalances,
  WalletConfig,
} from "../components/Web3Automation";

export interface OwnerId {
  address: string;
  id: number;
}

export interface OwnerIds {
  address: string | null | undefined;
  ids: number[];
}

// This function does not append connected address as it only deals with the services WCs
export const mergeWalletConfigs = (
  delegateWC: WalletConfig,
  warmWC: WalletConfig,
  address: string | null | undefined
): WalletConfig => {
  const init: WalletConfig = {
    coldWallet: [],
    hotWallet: "",
  };

  // No service(s) (delegate.cash and warm.xyz) are tied to connected wallet
  if (delegateWC.hotWallet !== address && warmWC.hotWallet !== address) {
    return init;
  }
  // Have both services (delegate.cash and warm.xyz) tied to connected wallet
  if (delegateWC.hotWallet && warmWC.hotWallet) {
    const merged = merge([delegateWC, warmWC]);
    return merged;
  }
  // Have either one or other service (delegate.cash and warm.xyz) tied to connected wallet
  if (delegateWC.hotWallet || warmWC.hotWallet) {
    const wc = warmWC.hotWallet ? warmWC : delegateWC;
    return wc;
  }
  return init;
};

// Merge walletConfigs from delegate.cash and warm.xyz
export const merge = (joined: WalletConfig[]): WalletConfig => {
  const finalWC: WalletConfig = {
    coldWallet: [],
    hotWallet: "",
  };
  // Adds all coldWallet addresses from both delegation services into one list. They all share same hotWallet
  joined.forEach((wc: WalletConfig) => {
    // initialize finalWC
    if (!finalWC.hotWallet) {
      finalWC.hotWallet = wc.hotWallet;
    }
    finalWC.coldWallet = [...wc.coldWallet, ...finalWC.coldWallet];
  });

  // Remove any duplicate addresses
  finalWC.coldWallet = finalWC.coldWallet.filter(
    (item, index) => finalWC.coldWallet.indexOf(item) === index
  );

  return finalWC;
};

// Map ActiveBalances[] as a format that <UwuPreview> component would render with its props values
export const fetchActiveBalances = (wb: WalletBalances[]): ActiveBalances[] => {
  const activeBalances: ActiveBalances[] = []; // final object we will use to render uwus in  <UwuPreview component
  // Only want balances greater than 0
  const walletBalances = wb.filter((a: WalletBalances) => a.balance > 0);
  // Add keys to WalletBalances interface making it ActiveBalances. keys is used for the contract call tokenOfOwnerByIndex
  walletBalances.forEach((a: WalletBalances) => {
    const final: ActiveBalances = {
      address: a.address,
      balance: a.balance,
      keys: Array.from(Array(a.balance).keys()),
      isDelegate: a.isDelegate,
    };
    activeBalances.push(final);
  });

  return activeBalances;
};
