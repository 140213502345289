import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Select, { OnChangeValue, SelectInstance, Theme } from "react-select";

import styled from "styled-components";

import { OptionType } from "../../config/options";
import { resetTrait, setTrait } from "../../state/uwuSlice";

const StyledOptionCategory = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const StyledSelect = styled(Select)<any>`
  * {
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
`;

interface Props {
  allowAll: boolean;
  name: string;
  ogTraits: Record<string, string> | null;
  options: OptionType[];
  tokensOwned: Record<string, boolean>;
  tokensOwned1155: Record<string, Record<string, boolean>>;
  traits: Record<string, string>;
  fullbody: boolean | null;
  uwuId: number | undefined;
}

const OptionCategory = ({
  allowAll,
  name,
  ogTraits,
  options,
  tokensOwned,
  tokensOwned1155,
  traits,
  fullbody,
  uwuId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
  const selectInputRef = useRef<SelectInstance>();

  useEffect(() => {
    setFilteredOptions(
      options.filter((value: OptionType) => {
        if (allowAll) {
          return true;
        }

        if (value.testOnly) {
          return false;
        }

        const normalTrait =
          !value.tokenRestriction &&
          !value.traitRestriction &&
          !value.tokenRestriction1155;
        if (normalTrait) return true;

        // Token Restriction
        let ownsToken = false;
        if (value.tokenRestriction) {
          if (tokensOwned[value.tokenRestriction]) ownsToken = true;
        }

        // Trait Restriction
        let ownsTrait = false;
        if (value.traitRestriction) {
          if (
            ogTraits &&
            ogTraits[value.traitRestriction.trait] ===
              value.traitRestriction.value
          )
            ownsTrait = true;
        }

        // ERC1155 Token Restriction
        let owns1155 = false;
        const restriction = value.tokenRestriction1155;
        if (restriction) {
          if (
            restriction.ids.some((id) => tokensOwned1155[restriction.token][id])
          ) {
            owns1155 = true;
          }
        }

        if (ownsToken || ownsTrait || owns1155) return true;

        return false;
      })
    );
    selectInputRef?.current?.clearValue();
  }, [uwuId]);

  const onChange = (value: OnChangeValue<OptionType, false>) => {
    if (value == null) return dispatch(resetTrait(name));

    // const selected = traits[name].toLowerCase() === value.name.toLowerCase();
    dispatch(
      setTrait({
        trait: name,
        value: value.name,
        fullbody: value.fullbody ? value.fullbody : false,
      })
    );
  };

  const getOption = (option: OptionType) => option.name;

  return (
    <StyledOptionCategory>
      <Header>{name}</Header>
      <StyledSelect
        ref={selectInputRef}
        options={filteredOptions}
        getOptionLabel={getOption}
        getOptionValue={getOption}
        isClearable
        isDisabled={
          (name === "left accessory" || name === "right accessory") && fullbody
        }
        isSearchable={false}
        onChange={onChange}
        styles={{
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderRadius: "12px",
            border: state.menuIsOpen
              ? "2px solid var(--primary)"
              : state.hasValue
              ? "2px solid var(--primary)"
              : "2px solid var(--primary-light)",
            borderBottom: state.menuIsOpen
              ? "4px solid var(--primary)"
              : state.hasValue
              ? "4px solid var(--primary)"
              : "4px solid var(--primary-light)",
            height: "4.8rem",
            boxShadow: "0 0",
            "&:hover": {
              borderColor: "var(--primary)",
            },
          }),
          menu: (baseStyles: any, state: any) => ({
            ...baseStyles,
            textAlign: "center",
            border: "2px solid var(--primary)",
            borderBottom: "4px solid var(--primary)",
          }),
          placeholder: (baseStyles: any, state: any) => ({
            ...baseStyles,
            color: "#e5e5e5",
          }),
          indicatorSeparator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            display: "none",
          }),
        }}
        theme={(theme: Theme) => {
          return {
            ...theme,
            borderRadius: 12,
            colors: {
              ...theme.colors,
              primary25: "var(--primary-light)",
              primary: "var(--primary)",
            },
          };
        }}
      />
    </StyledOptionCategory>
  );
};

export default OptionCategory;
