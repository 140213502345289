import { ReactNode } from "react";
import styled from "styled-components";
import exit from "../assets/ui/exit.svg";
import Button from "./Button";

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Background = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
`;

const Container = styled.div`
  position: relative;
  width: 40rem;
  background-color: var(--bg);
  border-radius: 1.6rem;
  padding: 2.4rem;
  border: 2px solid var(--border);
  border-bottom-width: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  cursor: pointer;
`;

const Exit = styled.img`
  height: 1.8rem;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1.2rem;
  width: 100%;
`;

const SubHeader = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  margin: 1.2rem 0;
  color: var(--sub);
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1.2rem;
`;

interface Props {
  show: boolean;
  close: () => void;
  header?: string;
  subHeader?: string;
  buttonText?: string;
  buttonAction?: () => void;
  children?: ReactNode;
  forced?: boolean;
}

const Popup = ({
  show,
  close,
  header,
  subHeader,
  buttonText,
  buttonAction,
  children,
  forced,
}: Props): JSX.Element | null => {
  if (!show) return null;

  return (
    <StyledPopup>
      <Background />
      <Container>
        {!forced && (
          <ExitButton onClick={() => close()}>
            <Exit src={exit} />
          </ExitButton>
        )}
        {header && <Header>{header}</Header>}
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
        {children && <Content>{children}</Content>}
        {buttonText && buttonAction && (
          <ButtonContainer>
            <Button primary click={buttonAction}>
              {buttonText}
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </StyledPopup>
  );
};

export default Popup;
