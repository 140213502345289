import styled from "styled-components";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useEthers } from "@usedapp/core";
import { useDispatch, useSelector } from "react-redux";

import Popup from "./Popup";
import { ALCHEMY_ID, GOERLI_ID } from "../app/globals";
import { selectConnectingWallet, walletConnected } from "../state/uiSlice";
import Button from "./Button";

export const supportedChainIds = [...Array.from(Array(1000).keys())];

export const walletConnectConnector = new WalletConnectConnector({
  supportedChainIds,
  rpc: {
    1: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`,
    5: `https://eth-goerli.g.alchemy.com/v2/${GOERLI_ID}`,
  },
  qrcode: true,
});

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 1.2rem;
  }
`;

const WalletSelector = (): JSX.Element => {
  const dispatch = useDispatch();
  const { account, activateBrowserWallet, activate } = useEthers();

  const connectingWallet = useSelector(selectConnectingWallet);

  return (
    <Popup
      show={connectingWallet || !account}
      close={() => dispatch(walletConnected())}
      header="Connect your wallet"
      forced={!account}
    >
      <Buttons>
        <Button primary click={() => activateBrowserWallet()}>
          Metamask
        </Button>
        <Button click={() => activate(walletConnectConnector)}>
          Walletconnect
        </Button>
      </Buttons>
    </Popup>
  );
};

export default WalletSelector;
