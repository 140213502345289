import styled from "styled-components";
import Socials from "./Socials";

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
`;

const Footer = (): JSX.Element => {
  return (
    <StyledFooter>
      <div />
      <Socials />
    </StyledFooter>
  );
};

export default Footer;
