import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { OnChangeValue, SelectInstance, Theme } from "react-select";

import styled from "styled-components";

import { OptionType } from "../../config/options";
import {
  resetAdminTrait,
  selectUwuState,
  setTrait,
} from "../../state/uwuSlice";

const StyledOptionCategory = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: capitalize;
`;

const StyledSelect = styled(Select)<any>`
  * {
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
`;

interface Props {
  name: string;
  options: OptionType[];
}

const fetchUwuStateValue = (
  uwuStateTraits: Record<string, string> | null,
  name: string
): OptionType | null => {
  const final: OptionType = {
    name: "",
  };
  if (uwuStateTraits) {
    Object.keys(uwuStateTraits).forEach((key: string) => {
      if (key === name) {
        final.name = uwuStateTraits[key];
      }
    });
  }
  const result = final.name ? final : null;
  return result;
};

const AdminOptionCategory = ({ name, options }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const selectInputRef = useRef<SelectInstance>();
  const uwuState = useSelector(selectUwuState);
  const defaultVal = fetchUwuStateValue(uwuState.traits, name);

  const onChange = (value: OnChangeValue<OptionType, false>) => {
    if (value == null) return dispatch(resetAdminTrait(name));

    dispatch(
      setTrait({
        trait: name,
        value: value.name,
        fullbody: value.fullbody ? value.fullbody : false,
      })
    );
  };

  const getOption = (option: OptionType) => option.name;

  return (
    <StyledOptionCategory>
      <Header>{name}</Header>
      <StyledSelect
        ref={selectInputRef}
        options={options}
        value={defaultVal}
        getOptionLabel={getOption || defaultVal}
        getOptionValue={getOption || defaultVal}
        isClearable
        isSearchable={false}
        onChange={onChange}
        styles={{
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderRadius: "12px",
            border: state.menuIsOpen
              ? "2px solid var(--primary)"
              : state.hasValue
              ? "2px solid var(--primary)"
              : "2px solid var(--primary-light)",
            borderBottom: state.menuIsOpen
              ? "4px solid var(--primary)"
              : state.hasValue
              ? "4px solid var(--primary)"
              : "4px solid var(--primary-light)",
            height: "4.8rem",
            boxShadow: "0 0",
            "&:hover": {
              borderColor: "var(--primary)",
            },
          }),
          menu: (baseStyles: any, state: any) => ({
            ...baseStyles,
            textAlign: "center",
            border: "2px solid var(--primary)",
            borderBottom: "4px solid var(--primary)",
          }),
          placeholder: (baseStyles: any, state: any) => ({
            ...baseStyles,
            color: "#e5e5e5",
          }),
          indicatorSeparator: (baseStyles: any, state: any) => ({
            ...baseStyles,
            display: "none",
          }),
        }}
        theme={(theme: Theme) => {
          return {
            ...theme,
            borderRadius: 12,
            colors: {
              ...theme.colors,
              primary25: "var(--primary-light)",
              primary: "var(--primary)",
            },
          };
        }}
      />
    </StyledOptionCategory>
  );
};

export default AdminOptionCategory;
