import { useEffect, useState } from "react";
import { useContractCall, useContractCalls, useEthers } from "@usedapp/core";
import { utils, Contract, ethers } from "ethers";

import uwucrew from "./abis/uwucrew.json";
import uwucrew_stamps from "./abis/uwucrew_stamps.json";
import persona_lamps from "./abis/persona_lamps.json";
import useGlobals from "../app/hooks/use-globals";
import { Metadata } from "../state/uwuSlice";
import options from "../config/options";
import { ETH_RPC, POLYGON_RPC } from "../app/globals";

export const useBalance = (account: string | null | undefined): number => {
  const globals = useGlobals();
  const [balance] = useContractCall({
    abi: new utils.Interface(uwucrew),
    address: globals.UWU,
    method: "balanceOf",
    args: [account],
  }) ?? [0];
  return Number(balance.toString());
};

export const useUwuId = (
  account: string | null | undefined,
  ownerIndex: number
): number => {
  const globals = useGlobals();
  const [uwuId] = useContractCall({
    abi: new utils.Interface(uwucrew),
    address: globals.UWU,
    method: "tokenOfOwnerByIndex",
    args: [account, ownerIndex],
  }) ?? [0];
  return Number(uwuId.toString());
};

export const useTokenUri = (uwuId: number): string => {
  const globals = useGlobals();
  const [tokenURI] = useContractCall({
    abi: new utils.Interface(uwucrew),
    address: globals.UWU,
    method: "tokenURI",
    args: [uwuId],
  }) ?? [""];
  return tokenURI;
};

const resolveIPFS = (ipfs: string) => {
  return `https://uwulabs.mypinata.cloud/ipfs/${ipfs.split("ipfs://")[1]}`;
};

export const useMetadata = (uwuId: number): Metadata | null => {
  const tokenURI = useTokenUri(uwuId);
  const [metadata, setMetadata] = useState(null);

  const fetchMetadata = async () => {
    const response = await fetch(resolveIPFS(tokenURI));
    const data = await response.json();
    setMetadata({ ...data, id: uwuId });
  };

  useEffect(() => {
    if (!tokenURI || metadata) return;
    fetchMetadata();
  }, [tokenURI]);

  return metadata;
};

export const useImage = (uwuId: number): string => {
  if (uwuId === -1) return "";
  return `https://firebasestorage.googleapis.com/v0/b/uwucrew-thumbnails/o/${uwuId.toString()}.png?alt=media`;
};

const removeDuplicates = (items: string[]): string[] => {
  return items.filter((item, index) => items.indexOf(item) === index);
};

const fetchTokenBalance = (
  uniqueTokens: string[],
  account: string | null | undefined,
  abi: any[]
) => {
  return (
    useContractCalls(
      uniqueTokens.map((token: string) => {
        return {
          abi: new utils.Interface(abi),
          address: token,
          method: "balanceOf",
          args: [account],
        };
      })
    ) ?? null
  );
};

export const useTokensOwned = (): Record<string, boolean> => {
  const { account } = useEthers();

  const tokens: string[] = [];
  for (let i = 0; i < options.length; i++) {
    for (let j = 0; j < options[i].options.length; j++) {
      const option = options[i].options[j];
      if (option.tokenRestriction) {
        tokens.push(option.tokenRestriction);
      }
    }
  }

  const uniqueTokens = removeDuplicates(tokens);

  const balances = fetchTokenBalance(uniqueTokens, account, persona_lamps);

  if (balances && balances[0]) {
    console.log(balances.length);
    console.log(balances[0].toString());
  }
  if (!balances) return {};

  const tokensOwned: Record<string, boolean> = {};
  for (let i = 0; i < uniqueTokens.length; i++) {
    const balance = balances[i];
    if (balance) tokensOwned[uniqueTokens[i]] = Number(balance.toString()) > 0;
  }
  return tokensOwned;
};

interface TokenCheck {
  token: string;
  id: string;
  chain: string;
}

export const use1155TokensOwned = (): Record<
  string,
  Record<string, boolean>
> => {
  const { account, chainId } = useEthers();
  const [tokensOwned, setTokensOwned] = useState<
    Record<string, Record<string, boolean>>
  >({});
  const tokenChecks: TokenCheck[] = [];
  for (let i = 0; i < options.length; i++) {
    for (let j = 0; j < options[i].options.length; j++) {
      const restriction = options[i].options[j].tokenRestriction1155;
      if (!restriction) continue;
      for (let k = 0; k < restriction.ids.length; k++) {
        const check = {
          token: restriction.token,
          id: restriction.ids[k],
          chain: restriction.chain,
        };
        // Need to stringify objects for equality comparisons
        if (
          tokenChecks.some(
            (t: TokenCheck) => JSON.stringify(t) === JSON.stringify(check)
          )
        )
          continue;
        tokenChecks.push(check);
      }
    }
  }
  const updateOwned = async () => {
    if (!account) return;
    const promises = tokenChecks.map(async (tokenCheck) => {
      // console.info(`TOKEN CHECK: ${JSON.stringify(tokenCheck)}`);
      if (tokenCheck.chain === "matic") {
        const abi = [
          "function balanceOf(address _owner, uint256 _id) external view returns (uint256)",
        ];
        const provider = new ethers.providers.JsonRpcProvider(POLYGON_RPC);
        const contract = new Contract(tokenCheck.token, abi, provider);
        return contract.balanceOf(account, tokenCheck.id);
      }
      const provider = new ethers.providers.JsonRpcProvider(ETH_RPC);
      const contract = new Contract(tokenCheck.token, uwucrew_stamps, provider);
      return contract.balanceOf(account, tokenCheck.id);
    });
    const results = await Promise.all(promises);

    const tokensOwned_: Record<string, Record<string, boolean>> = {};
    for (let i = 0; i < tokenChecks.length; i++) {
      if (tokensOwned_[tokenChecks[i].token]) {
        tokensOwned_[tokenChecks[i].token][tokenChecks[i].id] =
          results[i].gt(0);
      } else {
        tokensOwned_[tokenChecks[i].token] = {
          [tokenChecks[i].id]: results[i].gt(0),
        };
      }
    }
    setTokensOwned(tokensOwned_);
  };

  useEffect(() => {
    updateOwned();
  }, [account, chainId]);

  return tokensOwned;
};
