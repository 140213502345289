import { Attribute, Metadata } from "./uwuSlice";

const getAttribute = (metadata: Metadata, name: string): string => {
  return (
    metadata.attributes.find(
      (attribute: Attribute) => attribute.trait_type === name
    )?.value || ""
  );
};

export const getTraits = (metadata: Metadata): Record<string, string> => {
  const mapping: Record<string, string> = {
    background: getAttribute(metadata, "Background"),
    outfit: getAttribute(metadata, "Outfit"),
    skin: getAttribute(metadata, "Skin"),
    eyes: getAttribute(metadata, "Eyes"),
    hair: getAttribute(metadata, "Hair"),
    hat: getAttribute(metadata, "Hat"),
    mouth: getAttribute(metadata, "Mouth"),
    "left accessory": getAttribute(metadata, "Left Accessory"),
    "right accessory": getAttribute(metadata, "Right Accessory"),
  };
  return mapping;
};
