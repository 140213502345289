import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Button from "../../components/Button";
import { resetState, selectAdminUwuUrl, setState } from "../../state/uwuSlice";

import AdminOptions, {
  appendMissingTraitsOptions,
  dressingRoomOptions,
  ogOptions,
  mergeOptions,
} from "./AdminOptions";
import AdminUwu from "./AdminUwu";
import Header from "../../components/Header";
import { AdminOptionCategoryType } from "../../config/options";

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  margin: 3rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;

  button {
    margin: 0 1rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }
`;

const CaptureArea = styled.div`
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const generateRandomUwu = (
  options: AdminOptionCategoryType[]
): Record<string, string> => {
  const final: Record<string, string> = {};
  options.forEach((element: AdminOptionCategoryType) => {
    const randVal =
      element.options[Math.floor(Math.random() * element.options.length)].name;
    const randKey = element.trait.toLowerCase();
    final[randKey] = randVal;
  });

  return final;
};

const AdminPage = (): JSX.Element => {
  const url = useSelector(selectAdminUwuUrl);
  const dispatch = useDispatch();
  const mergedOptions: AdminOptionCategoryType[] = mergeOptions(
    dressingRoomOptions,
    ogOptions
  );
  const fullDressingRoomOptions = appendMissingTraitsOptions(
    dressingRoomOptions,
    ogOptions
  );
  const dressingRoomTraits = generateRandomUwu(fullDressingRoomOptions);
  const allTraits = generateRandomUwu(mergedOptions);
  console.log("mergedOptions", mergedOptions, "\n");
  console.log("dressingRoomTraits", dressingRoomTraits, "\n");
  console.log("mergedOptallTraitsions", allTraits, "\n");
  const [link, setLink] = useState<string | null>(null);
  const captureRef = useRef<HTMLDivElement>(null);

  const getDownloadLink = async (): Promise<void> => {
    if (!url) return;
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    setLink(imageURL);
  };

  useEffect(() => {
    getDownloadLink();
  }, [url]);

  return (
    <>
      <Header />
      <StyledHomePage>
        <Container>
          <CaptureArea ref={captureRef}>
            <AdminUwu />
          </CaptureArea>
          <ButtonContainer>
            <Button click={() => dispatch(resetState())}>Reset</Button>
            <Button click={() => dispatch(setState(allTraits))}>
              Randomize
            </Button>
            <Button click={() => dispatch(setState(dressingRoomTraits))}>
              Randomize OG
            </Button>

            {link && url && (
              <a
                download={`admin_uwu.${
                  url.indexOf("transparent") > -1 ? "png" : "jpg"
                }`}
                href={link}
                title={`admin_uwu.${
                  url.indexOf("transparent") > -1 ? "png" : "jpg"
                }`}
              >
                <Button click={() => console.log("meow")}>Download uwu</Button>
              </a>
            )}
          </ButtonContainer>
        </Container>
        <Container>
          <AdminOptions />
        </Container>
      </StyledHomePage>
    </>
  );
};

export default AdminPage;
