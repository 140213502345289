import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectAdminUwuUrl } from "../../state/uwuSlice";
import spinner from "../../assets/ui/loading.svg";

const StyledUwu = styled.div`
  position: relative;
  display: flex;
  width: 35vw;
  height: 35vw;

  @media (max-width: 600px) {
    width: 100%;
    height: 100vw;
  }
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  @media (max-width: 600px) {
    height: 100vw;
  }
`;

const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 1;
  background: var(--primary-light);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35vw;

  @media (max-width: 600px) {
    width: 100%;
    height: 100vw;
  }
`;

const Spinner = styled.img`
  width: 20%;
`;

const AdminUwu = (): JSX.Element => {
  const url = useSelector(selectAdminUwuUrl);
  const [loaded, setLoaded] = useState(false);
  console.log("url", url);

  useEffect(() => {
    setLoaded(false);
  }, [url]);

  return (
    <StyledUwu>
      {url && (
        <Image
          src={url}
          alt="uwu"
          onLoad={() =>
            setTimeout(() => {
              setLoaded(true);
            }, 300)
          }
        />
      )}
      {url && !loaded && (
        <LoadingScreen>
          <Spinner src={spinner} alt="Spinner" />
        </LoadingScreen>
      )}
      {!url && <LoadingScreen />}
    </StyledUwu>
  );
};

export default AdminUwu;
