import styled from "styled-components";
import Container from "../../components/Container";
import options, {
  OptionType,
  AdminOptionCategoryType,
  OptionCategoryType,
} from "../../config/options";
import ADMIN_TRAITS from "../../assets/admin/traits.json";
import AdminOptionCategory from "./AdminOptionCategory";

interface AdminTraits {
  Background: string[];
  Skin: string[];
  Outfit: string[];
  "Right Accessory": string[];
  "Left Accessory": string[];
  Hair: string[];
  Hat: string[];
  Eyes: string[];
  Mouth: string[];
}

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40vw;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Header = styled.div`
  font-size: 4.5rem;
  font-weight: 700;
  width: 100%;
  margin-bottom: 3rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(15rem, 1fr));
  grid-gap: 2rem;
  width: 100%;
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
`;

const generateAdminOptions = (
  ADMIN_TRAITS: AdminTraits
): AdminOptionCategoryType[] => {
  const adminOptions: AdminOptionCategoryType[] = [];
  Object.entries(ADMIN_TRAITS).forEach((entry) => {
    const [key, values] = entry;
    const options: OptionType[] = [];
    values.forEach((element: string) => {
      const trait: OptionType = {
        name: element,
      };
      options.push(trait);
    });
    options.sort((a: OptionType, b: OptionType) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    options.sort((a: OptionType, b: OptionType) => {
      const first = "No ";
      return a.name.includes(first) ? -1 : b.name.includes(first) ? 1 : 0;
    });
    const adminOption: AdminOptionCategoryType = {
      trait: key.toLowerCase(),
      options,
    };
    adminOptions.push(adminOption);
  });
  return adminOptions;
};

const generatedressingRoomOptions = (options: OptionCategoryType[]) => {
  const dressingRoomOptions: AdminOptionCategoryType[] = [];

  options.forEach((element: OptionCategoryType) => {
    const options: OptionType[] = [];
    const ogOption: AdminOptionCategoryType = {
      trait: element.trait,
      options,
    };
    element.options.forEach((element: OptionType) => {
      const option: OptionType = {
        name: element.name,
      };
      ogOption.options.push(option);
    });
    dressingRoomOptions.push(ogOption);
  });
  return dressingRoomOptions;
};

export const ogOptions = generateAdminOptions(ADMIN_TRAITS);
export const dressingRoomOptions = generatedressingRoomOptions(options);

export const mergeOptions = (
  dressingRoomOptions: AdminOptionCategoryType[],
  ogOptions: AdminOptionCategoryType[]
): AdminOptionCategoryType[] => {
  const mergedOptions: AdminOptionCategoryType[] = [];
  ogOptions.forEach((v) => {
    const pairOptions = dressingRoomOptions.find((o) => o.trait === v.trait);
    if (pairOptions) {
      const mergd = {
        trait: v.trait,
        options: [...v.options, ...pairOptions.options],
      };
      mergedOptions.push(mergd);
    } else {
      const mergd = {
        trait: v.trait,
        options: [...v.options],
      };
      mergedOptions.push(mergd);
    }
  });

  return mergedOptions;
};

export const appendMissingTraitsOptions = (
  dressingRoomOptions: AdminOptionCategoryType[],
  ogOptions: AdminOptionCategoryType[]
): AdminOptionCategoryType[] => {
  const fullDressingRoomOption: AdminOptionCategoryType[] = dressingRoomOptions;
  ogOptions.forEach((v) => {
    const hasTrait = dressingRoomOptions.some((obj) =>
      Object.values(obj).includes(v.trait)
    );
    if (!hasTrait) {
      const missingOptions = {
        trait: v.trait,
        options: [...v.options],
      };
      fullDressingRoomOption.push(missingOptions);
    }
  });

  return fullDressingRoomOption;
};

const AdminOptions = (): JSX.Element | null => {
  const ogOptions = generateAdminOptions(ADMIN_TRAITS);
  const dressingRoomOptions = generatedressingRoomOptions(options);

  const mergedOptions: AdminOptionCategoryType[] = mergeOptions(
    dressingRoomOptions,
    ogOptions
  );

  if (!mergedOptions) return null;

  return (
    <Container>
      <StyledOptions>
        <Header>Dressing Room</Header>
        <Grid>
          {mergedOptions.map((option: AdminOptionCategoryType) => (
            <AdminOptionCategory
              key={option.trait}
              name={option.trait}
              options={option.options}
            />
          ))}
        </Grid>
      </StyledOptions>
    </Container>
  );
};

export default AdminOptions;
