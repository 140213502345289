import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTraits } from "./helpers";
import { RootState } from "./store";

export interface AdminTraits {
  Background: string[];
  Skin: string[];
  Outfit: string[];
  "Right Accessory": string[];
  "Left Accessory": string[];
  Hair: string[];
  Hat: string[];
  Eyes: string[];
  Mouth: string[];
}

export interface Attribute {
  trait_type: string;
  value: string;
}

export interface Metadata {
  name: string;
  description: string;
  id: number;
  image: string;
  attributes: Attribute[];
}

export interface UwuState {
  traits: Record<string, string> | null;
  metadata: Metadata | null;
  fullbody: boolean | null;
}

const initialState: UwuState = {
  traits: null,
  metadata: null,
  fullbody: null,
};

export const uwuSlice = createSlice({
  name: "uwu",
  initialState,
  reducers: {
    setMetadata: (state, action: PayloadAction<Metadata>) => {
      state.metadata = action.payload;
      state.traits = getTraits(action.payload);
    },
    setTrait: (
      state,
      action: PayloadAction<{ trait: string; value: string; fullbody: boolean }>
    ) => {
      if (!state.traits) {
        state.traits = {};
      }
      state.traits[action.payload.trait] = action.payload.value;
      if (action.payload.trait === "outfit" && action.payload.fullbody)
        state.fullbody = action.payload.fullbody;
    },
    resetTrait: (state, action: PayloadAction<string>) => {
      if (!state.traits || !state.metadata) return;
      state.traits[action.payload] = getTraits(state.metadata)[action.payload];
      if (action.payload === "outfit") state.fullbody = null;
    },
    resetAdminTrait: (state, action: PayloadAction<string>) => {
      if (!state.traits) return;
      delete state.traits[action.payload];
    },
    resetState: (state) => {
      if (state.traits || state.metadata) {
        state.traits = null;
        state.metadata = null;
      }
    },
    setState: (state, action: PayloadAction<Record<string, string>>) => {
      state.traits = action.payload;
    },
  },
});

export const {
  setMetadata,
  setTrait,
  resetTrait,
  resetAdminTrait,
  setState,
  resetState,
} = uwuSlice.actions;

export const selectTraits = (state: RootState): Record<string, string> | null =>
  state.uwu.traits;

export const selectFullbody = (state: RootState): boolean | null =>
  state.uwu.fullbody;

export const selectOriginalTraits = (
  state: RootState
): Record<string, string> | null =>
  state.uwu.metadata ? getTraits(state.uwu.metadata) : null;

export const selectId = (state: RootState): number | undefined =>
  state.uwu.metadata?.id;

export const selectUwuSelected = (state: RootState): boolean =>
  !!state.uwu.metadata;

export const selectHasModification = (state: RootState): boolean => {
  const ogTraits = selectOriginalTraits(state);
  return !(JSON.stringify(ogTraits) === JSON.stringify(state.uwu.traits));
};

export const selectUwuUrl = (state: RootState): string | null => {
  const { uwu } = state;
  if (!uwu.metadata) return null;

  if (!selectHasModification(state)) {
    return `https://uwulabs.mypinata.cloud/ipfs/QmY1TQeJ31T6juvx32mBevw2pTq5yLFaFqcfREnaJeuhTU/${uwu.metadata.id}.png?alt=media`;
  }

  if (!uwu.traits) {
    return null;
  }

  let url = `https://dressing-room-cwgb4whsrq-uc.a.run.app/generate?TokenID=${uwu.metadata.id}`;
  if (uwu.traits.background)
    url += `&Background=${reduceText(uwu.traits.background)}`;
  if (uwu.traits.hat) url += `&Hat=${reduceText(uwu.traits.hat)}`;
  if (uwu.traits.outfit) url += `&Outfit=${reduceText(uwu.traits.outfit)}`;
  if (uwu.traits["left accessory"])
    url += `&LeftAccessory=${reduceText(uwu.traits["left accessory"])}`;
  if (uwu.traits["right accessory"])
    url += `&RightAccessory=${reduceText(uwu.traits["right accessory"])}`;
  if (uwu.traits.eyewear) url += `&Eyewear=${reduceText(uwu.traits.eyewear)}`;
  return url;
};

export const selectAdminUwuUrl = (state: RootState): string | null => {
  const { uwu } = state;

  if (uwu.traits) {
    const hasAllTraits =
      uwu.traits.hat &&
      uwu.traits.background &&
      uwu.traits.outfit &&
      uwu.traits["left accessory"] &&
      uwu.traits["right accessory"] &&
      uwu.traits.skin &&
      uwu.traits.eyes &&
      uwu.traits.hair &&
      uwu.traits.mouth;

    if (hasAllTraits) {
      const url = `https://dressing-room-cwgb4whsrq-uc.a.run.app/generate?AdminPass=keepituwu&Background=${reduceText(
        uwu.traits.background
      )}&Hat=${reduceText(uwu.traits.hat)}&Outfit=${reduceText(
        uwu.traits.outfit
      )}&LeftAccessory=${reduceText(
        uwu.traits["left accessory"]
      )}&RightAccessory=${reduceText(
        uwu.traits["right accessory"]
      )}&Skin=${reduceText(uwu.traits.skin)}&Eyes=${reduceText(
        uwu.traits.eyes
      )}&Hair=${reduceText(uwu.traits.hair)}&Mouth=${reduceText(
        uwu.traits.mouth
      )}`;
      return url;
    }
  }
  return null;
};

export const selectUwuState = (state: RootState): UwuState => {
  return state.uwu;
};

const reduceText = (text: string): string => {
  return text.toLowerCase().replaceAll(" ", "").replaceAll("-", "");
};

export default uwuSlice.reducer;
