import { useEthers } from "@usedapp/core";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectError } from "../state/errorSlice";

interface ButtonProps {
  primary?: boolean;
  selected?: boolean;
  wide?: boolean;
}

const Container = styled.button`
  width: ${(p: ButtonProps) => (p.wide ? "100%" : "auto")};
  position: relative;
  cursor: pointer;
  margin-bottom: 4px;

  filter: brightness(1);
  transition: all 0.3s;
  :hover {
    filter: brightness(1.03);
    div:first-child {
    }
    div:last-child {
      transform: translateY(-2px);
    }
  }

  :active {
    div:first-child {
    }
    div:last-child {
      transform: translateY(2px);
    }
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transform: none;
  }
`;

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 12px;
  transform: translateY(4px);

  background: ${(p: ButtonProps) => {
    if (p.primary) return "var(--primary-dark)";
    if (p.selected) return "var(--primary)";
    return "var(--border)";
  }};
`;

const StyledButton = styled.div`
  position: relative;
  padding: 0 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  height: 4.8rem;
  transform: translateY(0);
  transition: all 0.3s;

  border: ${(p: ButtonProps) => {
    if (p.primary) return "none";
    if (p.selected) return "2px solid var(--primary)";
    return "2px solid var(--border)";
  }};
  border-bottom: none;
  background: ${(p: ButtonProps) => {
    if (p.primary) return "var(--primary)";
    if (p.selected) return "var(--primary-light)";
    return "var(--bg)";
  }};
  color: ${(p: ButtonProps) => {
    if (p.primary) return "var(--bg)";
    if (p.selected) return "var(--primary)";
    return "var(--sub)";
  }};
`;

interface Props {
  click: () => void;
  primary?: boolean;
  selected?: boolean;
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
  wide?: boolean;
}

const Button = ({
  children,
  click,
  primary,
  selected,
  disabled,
  loading,
  wide,
}: Props): JSX.Element => {
  const { account, activateBrowserWallet } = useEthers();
  const error = useSelector(selectError);
  const [pending, setPending] = useState(false);

  const isWeb3 = loading !== undefined;

  useEffect(() => {
    if (error || loading) setPending(false);
  }, [error, loading]);

  return (
    <Container wide={wide} disabled={disabled || loading || pending}>
      <Shadow primary={primary} selected={selected} />
      <StyledButton
        onClick={() => {
          if (loading || disabled || pending) return;
          if (isWeb3) setPending(true);
          if (isWeb3 && !account) activateBrowserWallet();
          else click();
        }}
        primary={primary}
        selected={selected}
      >
        {isWeb3 && !account
          ? "Connect Wallet"
          : loading
          ? "Loading..."
          : children}
      </StyledButton>
    </Container>
  );
};

export default Button;
