import { createWatcher, ICall, IConfig, IUpdate } from "@makerdao/multicall";
import { BigNumber } from "ethers";
import { ETH_GLOBALS, MAIN_RPC, MULTICALL_MAINNET } from "../app/globals";
import { ActiveBalances, WalletBalances } from "../components/Web3Automation";
import { OwnerId, OwnerIds } from "./delegation";

const BALANCE_OF = "balanceOf";
const TOKEN_OWNER = "tokenOfOwnerByIndex";

const generateBalanceCalls = (
  mergedAddresses: (string | null | undefined)[]
): ICall[] => {
  const finalCalls: ICall[] = [];

  const baseCall = {
    target: ETH_GLOBALS.UWU,
    call: [],
    returns: [],
  };

  mergedAddresses.forEach((address: string | null | undefined) => {
    const updatedCall: ICall = {
      ...baseCall,
    };
    if (address) {
      updatedCall.call = [`${BALANCE_OF}(address)(uint256)`, address];
      updatedCall.returns = [
        [`${address}`, (val: BigNumber) => val.toNumber()],
      ];
      finalCalls.push(updatedCall);
    }
  });

  return finalCalls;
};

const generateTokenIdsCalls = (activeBals: ActiveBalances[]): ICall[] => {
  const finalCalls: ICall[] = [];

  const baseCall = {
    target: ETH_GLOBALS.UWU,
    call: [],
    returns: [],
  };
  activeBals.forEach((ab: ActiveBalances) => {
    ab.keys.forEach((id: number) => {
      if (ab.address) {
        const updatedCall: ICall = {
          ...baseCall,
        };
        updatedCall.call = [
          `${TOKEN_OWNER}(address,uint256)(uint256)`,
          ab.address,
          `${id}`,
        ];
        updatedCall.returns = [
          [`${ab.address}${id}`, (val: BigNumber) => val.toNumber()],
        ];
        finalCalls.push(updatedCall);
      }
    });
  });

  return finalCalls;
};

export const fetchBalanceMulticall = async (
  mergedAddresses: (string | null | undefined)[]
) => {
  const batchedResults: WalletBalances[] = [];

  const config: Partial<IConfig> = {
    rpcUrl: MAIN_RPC,
    multicallAddress: MULTICALL_MAINNET,
  };

  const calls: ICall[] = generateBalanceCalls(mergedAddresses);

  const watcher = createWatcher(calls, config);

  watcher.subscribe((update: IUpdate) => {
    let result: WalletBalances = { address: "", balance: [] };
    result.balance = update.value;
    result = {
      ...result,
      address: update.args[0],
    };
    batchedResults.push(result);
  });

  await watcher.start();

  watcher.stop();

  return batchedResults;
};

export const fetchTokenIdMulticall = async (ab: ActiveBalances[]) => {
  const batchedResults: OwnerId[] = [];
  const final: OwnerIds[] = [];

  const config: Partial<IConfig> = {
    rpcUrl: MAIN_RPC,
    multicallAddress: MULTICALL_MAINNET,
  };

  const calls: ICall[] = generateTokenIdsCalls(ab);

  const watcher = createWatcher(calls, config);

  watcher.subscribe((update: IUpdate) => {
    let result: OwnerId = { address: "", id: -1 };
    result.id = update.value;
    result = {
      ...result,
      address: update.args[0],
    };
    batchedResults.push(result);
  });

  await watcher.start();

  batchedResults.forEach((ownerId: OwnerId) => {
    const existing = final.filter((v) => {
      return v.address === ownerId.address;
    });
    if (existing.length) {
      const existingIndex = final.indexOf(existing[0]);
      final[existingIndex].ids = final[existingIndex].ids.concat(ownerId.id);
    } else {
      const temp: OwnerIds = {
        address: ownerId.address,
        ids: [ownerId.id],
      };
      final.push(temp);
    }
  });

  watcher.stop();

  return final;
};
