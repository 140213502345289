import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import exit from "../../assets/ui/exit.svg";
import { WalletBalances } from "../../components/Web3Automation";
import { CC0_THE_CREW } from "../../config/options";
import { useBalance } from "../../contracts/views";
import { selectDelegates } from "../../state/delegateCash";
import { selectTraits } from "../../state/uwuSlice";
import { selectWarmWalletConfig } from "../../state/warmXYZ";
import {
  fetchActiveBalances,
  mergeWalletConfigs,
} from "../../utils/delegation";
import { fetchBalanceMulticall } from "../../utils/multicall";
import { UwuBalance } from "./UwuBalance";
import heart_icon from "../../assets/ui/heart.svg";

interface SelectorProps {
  show: boolean;
}

const StyledUwuSelector = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  display: ${(props: SelectorProps) => (props.show ? "flex" : "none")};

  padding: 8rem;
  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

const Exit = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--bg);

  border-radius: 2rem;
  padding: 2.4rem;
  border: 2px solid var(--border);
  border-bottom-width: 4px;
`;

const ExitButton = styled.button`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  cursor: pointer;
`;

const ExitImage = styled.img`
  width: 2rem;
`;

const Header = styled.div`
  font-size: 4.5rem;
  font-weight: 700;
  width: 100%;
`;

const Empty = styled.div`
  font-size: 2rem;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubHeader = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  margin: 1.2rem 0;
  color: var(--glacier);
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const DelegateKeyContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface Props {
  address: string | null | undefined;
  show: boolean;
  close: () => void;
}

// WalletConfig or WC is refrenced in the comment for abbreviation purposes.
const UwuSelector = ({ address, show, close }: Props): JSX.Element => {
  // This is the connected wallet balance
  const balance = useBalance(address);
  const traits = useSelector(selectTraits);

  // Gets redux state value (WalletConfig interface) for delegate.cash
  const delegateCash = useSelector(selectDelegates);

  // Gets redux state value (WalletConfig interface) for warm.xyz
  const warmXYZ = useSelector(selectWarmWalletConfig);
  // Take all values that we fetched from services and return appropriate result depending on what is defined and what isnt
  const mergedWC = mergeWalletConfigs(delegateCash, warmXYZ, address);

  // This is set in the useEffect hook that takes the mergedWC (delegate.cash and warm.xyz responses merged into one interface)
  // and fetched the balance of each wallet that was fetched from those services
  const [uwuWallets, setUwuWallets] = useState<WalletBalances[]>([]);

  // If address defined return mergedWC (delegate.cash WC amd warm.xyz WC) and connected WC (same as address)
  const walletBalances: WalletBalances[] = address
    ? [
        ...uwuWallets,
        {
          address,
          balance,
          isDelegate: false,
        },
      ]
    : [];

  // Only want ActiveBalances with balances that are greater than zero
  const activeBalances = fetchActiveBalances(walletBalances);
  console.log("activeBalances", activeBalances);

  let cc0 = [389, 1461, 3209, 3489, 4542, 6153, 6785, 7643, 7953, 8006, 8614];
  if (address !== CC0_THE_CREW) {
    cc0 = [];
  }

  useEffect(() => {
    // Fetch all wallet balances
    const fetchWalletBalances = async () => {
      let mergedWB: WalletBalances[] = [];
      const addresses: string[] = mergedWC.coldWallet;

      if (addresses !== undefined && addresses.length !== 0) {
        const results = await fetchBalanceMulticall(addresses);
        mergedWB = uwuWallets.concat(results);
        const final = mergedWB.filter(
          (item, pos) => mergedWB.indexOf(item) === pos
        );
        final.map((o: WalletBalances) => {
          o.isDelegate = true;
          return o;
        });
        setUwuWallets(final);
      }
    };
    if (mergedWC.coldWallet.length === 0) return;
    fetchWalletBalances().catch(console.error);
  }, [delegateCash, warmXYZ]);

  return (
    <StyledUwuSelector show={show || !traits}>
      {traits && <Exit onClick={() => close()} />}
      <Container>
        <Header>Select uwu</Header>
        {uwuWallets.length !== 0 && (
          <DelegateKeyContainer>
            <Icon src={heart_icon} alt="heart_icon" />
            <SubHeader>delegated</SubHeader>
          </DelegateKeyContainer>
        )}
        {activeBalances.length === 0 && <Empty>No uwus found in wallet</Empty>}
        {activeBalances.length !== 0 && (
          <UwuBalance active={activeBalances} close={close} cc0={cc0} />
        )}
        {traits && (
          <ExitButton onClick={() => close()}>
            <ExitImage src={exit} alt="Exit icon" />
          </ExitButton>
        )}
      </Container>
    </StyledUwuSelector>
  );
};

export default UwuSelector;
