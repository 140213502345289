import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import HomePage from "./pages/home/HomePage";
import AdminPage from "./pages/home/AdminPage";

const Routing = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
        </Route>
        {process.env.REACT_APP_TEST_FLAG === "true" ? (
          <Route path="/admin" element={<AdminPage />} />
        ) : null}
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
