export const ALCHEMY_ID = "GxRQWP_PwJffWzqTq8xJ9WlPreJDt843";
export const GOERLI_ID = "FtimaZ0L7Ny_yPyJD26V12TxLDgpo-qu";
export const POLYGON_RPC = "https://polygon-rpc.com";
export const ETH_RPC = "https://eth.public-rpc.com";
export const WARM_XYZ = "0xc3aa9bc72bd623168860a1e5c6a4530d3d80456c";
export const DELEGATE_CASH = "0x00000000000076a84fef008cdabe6409d2fe638b";

export const MAIN_RPC = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_ID}`;
export const MULTICALL_MAINNET = "0xeefba1e63905ef1d7acba5a8513c70307c1ce441";
export interface GlobalsType {
  UWU: string;
}

export const ETH_GLOBALS: GlobalsType = {
  UWU: "0xF75140376D246D8B1E5B8a48E3f00772468b3c0c",
};

export const GOERLI_GLOBALS: GlobalsType = {
  UWU: "0xF75140376D246D8B1E5B8a48E3f00772468b3c0c",
};
