import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useEthers } from "@usedapp/core";

import Button from "../../components/Button";
import { selectId, selectUwuUrl } from "../../state/uwuSlice";
import Options from "./Options";
import Uwu from "./Uwu";
import UwuSelector from "./UwuSelector";

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;

  button {
    margin: 0 1rem;
  }

  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }
`;

const CaptureArea = styled.div`
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const HomePage = (): JSX.Element => {
  const id = useSelector(selectId);
  const url = useSelector(selectUwuUrl);
  const { account, chainId } = useEthers();

  const [showUwuSelector, setShowUwuSelector] = useState(false);
  const [link, setLink] = useState<string | null>(null);
  const captureRef = useRef<HTMLDivElement>(null);

  const getDownloadLink = async (): Promise<void> => {
    if (!url) return;
    const image = await fetch(url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    setLink(imageURL);
  };

  useEffect(() => {
    setShowUwuSelector(true);
  }, [account, chainId]);

  useEffect(() => {
    getDownloadLink();
  }, [url]);

  return (
    <StyledHomePage>
      <Container>
        <CaptureArea ref={captureRef}>
          <Uwu />
        </CaptureArea>
        <ButtonContainer>
          <Button click={() => setShowUwuSelector(true)}>Change uwu</Button>
          {id && link && url && (
            <a
              download={`uwu-${id}.${
                url.indexOf("transparent") > -1 ? "png" : "jpg"
              }`}
              href={link}
              title={`uwu-${id}.${
                url.indexOf("transparent") > -1 ? "png" : "jpg"
              }`}
            >
              <Button click={() => console.log("meow")}>Download uwu</Button>
            </a>
          )}
        </ButtonContainer>
      </Container>
      <Container>
        <Options address={account} />
      </Container>
      <UwuSelector
        address={account}
        show={showUwuSelector}
        close={() => {
          setShowUwuSelector(false);
          getDownloadLink();
        }}
      />
    </StyledHomePage>
  );
};

export default HomePage;
